import dateHelper from "./dateHelper";

export default {
    difference(newArr, oldArr) {
        let added = [], deleted = [];
        deleted = oldArr.filter(val => !newArr.includes(val));
        added = newArr.filter(val => !oldArr.includes(val));
        return {
            added,
            deleted
        }
    },
    removeDuplicates(items) {
        return [...new Set(items)];
    },
    sortBasedOnDate(items, property) {
        return items.sort((a,b) => {
            if (a[property] === null && b[property] === null) {
                return 0;
            }
            if (a[property] === null) {
                return 1;
            }
            if (b[property] === null) {
                return -1;
            }
            return new Date(dateHelper.formatDateTime(a[property])) - new Date(dateHelper.formatDateTime(b[property]))
        });
    },
    sortBasedOnProperty(items, property) { 
        return items.sort((a,b) => (a[property] > b[property]) ? 1 : ((b[property] > a[property]) ? -1 : 0));
    }
}