import { STORE } from '../../../enums/store';
import VehicleLookupService from '../../../services/lookups/vehicleLookupService';
export default {
    LOAD_VEHICLE_LOOKUPS({ dispatch }, utils) {
        dispatch(`${STORE.VEHICLE_LOOKUP}/LOAD_VEHICLE_MANUFACTURER`, utils, { root: true });
        dispatch(`${STORE.VEHICLE_LOOKUP}/LOAD_VEHICLE_MODELS`, utils, { root: true });
        dispatch(`${STORE.VEHICLE_LOOKUP}/LOAD_VEHICLE_DERIVATIVES`, utils, { root: true });         
        dispatch(`${STORE.VEHICLE_LOOKUP}/LOAD_FUEL_TYPES`, utils, { root: true });         
        dispatch(`${STORE.VEHICLE_LOOKUP}/LOAD_FLEET_TYPES`, utils, { root: true });         
        dispatch(`${STORE.VEHICLE_LOOKUP}/LOAD_VEHICLE_TYPES`, utils, { root: true });         
        dispatch(`${STORE.VEHICLE_LOOKUP}/LOAD_TYRE_BRAND_TYPES`, utils, { root: true });         
        dispatch(`${STORE.VEHICLE_LOOKUP}/LOAD_VEHICLE_GROSS_WEIGHTS`, utils, { root: true });         
        dispatch(`${STORE.VEHICLE_LOOKUP}/LOAD_VEHICLE_MOT_CLASSES`, utils, { root: true });         
    },
    LOAD_VEHICLE_MANUFACTURER({ commit }, utils) {
        const vehicleLookupService = new VehicleLookupService(utils);
        vehicleLookupService.getVehicleManufacturers().then(resp => {
            commit(`${STORE.VEHICLE_LOOKUP}/SET_VEHICLE_MANUFACTURER_LOOKUP`, resp, { root: true });
        }); 
    },
    LOAD_VEHICLE_MODELS({ commit }, utils) {
        const vehicleLookupService = new VehicleLookupService(utils);
        vehicleLookupService.getVehicleModels().then(resp => {
            commit(`${STORE.VEHICLE_LOOKUP}/SET_VEHICLE_MODEL_LOOKUP`, resp, { root: true });
        });
    },
    LOAD_VEHICLE_DERIVATIVES({ commit }, utils) {
        const vehicleLookupService = new VehicleLookupService(utils);
        vehicleLookupService.getVehicleDerivatives().then(resp => {
            commit(`${STORE.VEHICLE_LOOKUP}/SET_VEHICLE_DERIVATIVE_LOOKUP`, resp, { root: true });
        });
    },
    LOAD_FUEL_TYPES({ commit }, utils) {
        const vehicleLookupService = new VehicleLookupService(utils);
        vehicleLookupService.getFuelTypes().then(resp => {
            commit(`${STORE.VEHICLE_LOOKUP}/SET_FUEL_TYPES`, resp, { root: true });
        });
    },
    LOAD_FLEET_TYPES({ commit }, utils) {
        const vehicleLookupService = new VehicleLookupService(utils);
        vehicleLookupService.getFleetTypes().then(resp => {
            commit(`${STORE.VEHICLE_LOOKUP}/SET_FLEET_TYPES`, resp, { root: true });
        });
    },
    LOAD_VEHICLE_TYPES({ commit }, utils) {
        const vehicleLookupService = new VehicleLookupService(utils);
        vehicleLookupService.getVehicleTypes().then(resp => {
            commit(`${STORE.VEHICLE_LOOKUP}/SET_VEHICLE_TYPES`, resp, { root: true });
        });
    },
    LOAD_TYRE_BRAND_TYPES({ commit }, utils) {
        const vehicleLookupService = new VehicleLookupService(utils);
        vehicleLookupService.getTyreBrandTypes().then(resp => {
            commit(`${STORE.VEHICLE_LOOKUP}/SET_TYRE_BRAND_TYPES`, resp, { root: true });
        });
    },
    LOAD_VEHICLE_GROSS_WEIGHTS({ commit }, utils) {
        const vehicleLookupService = new VehicleLookupService(utils);
        vehicleLookupService.getVehicleGrossWeights().then(resp => {
            commit(`${STORE.VEHICLE_LOOKUP}/SET_GROSS_VEHICLE_WEIGHTS`, resp, { root: true });
        });
    },
    LOAD_VEHICLE_MOT_CLASSES({ commit }, utils) {
        const vehicleLookupService = new VehicleLookupService(utils);
        vehicleLookupService.getVehicleMOTClasses().then(resp => {
            commit(`${STORE.VEHICLE_LOOKUP}/SET_MOT_CLASSES`, resp, { root: true });
        });
    }
}