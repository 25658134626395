import CustomerService from '../../services/customerService';
import SupplierService from '../../services/supplierService';
import { STORE } from "../../enums/store";
import arrayHelper from '../../helpers/arrayHelper';
export default {
    LOAD_ALL_LOOKUPS({ dispatch }, utils) {
        dispatch(`${STORE.VEHICLE_LOOKUP}/LOAD_VEHICLE_LOOKUPS`, utils, { root: true });   
        dispatch(`${STORE.JOBTRACKER_LOOKUP}/LOAD_JOBTRACKER_LOOKUPS`, utils, { root: true });   
        dispatch(`${STORE.CONVERSION_LOOKUP}/LOAD_CONVERSION_LOOKUPS`, utils, { root: true });   
        dispatch(`${STORE.EQUIPMENT_LOOKUP}/LOAD_EQUIPMENT_LOOKUPS`, utils, { root: true });
        dispatch(`${STORE.CONTRACT_LOOKUP}/LOAD_CONTRACT_LOOKUPS`, utils, { root: true });
        dispatch(`${STORE.SERVICE_SCHEDULE_LOOKUP}/LOAD_SERVICE_SCHEDULE_LOOKUPS`, utils, { root: true });
        dispatch(`${STORE.FINANCIAL_LOOKUP}/LOAD_FINANCIAL_LOOKUPS`, utils, { root: true });
        dispatch(`${STORE.LOCATION_LOOKUP}/LOAD_LOCATION_LOOKUPS`, utils, { root: true });
        dispatch(`${STORE.JOBTRACKER}/LOAD_JOB_STATUSES`, utils, { root: true });
        dispatch(`${STORE.APP}/GET_ALL_CUSTOMERS`, utils, { root: true });
        dispatch(`${STORE.APP}/GET_ALL_SUPPLIERS`, utils, { root: true });
    },
    async GET_ALL_CUSTOMERS({ commit }, utils) {
        const customerService = new CustomerService(utils)
        let customers = await customerService.getCustomers(process.env.VUE_APP_API_REQUEST_PERPAGE);
        commit(`${STORE.APP}/SET_ALL_CUSTOMERS`, arrayHelper.sortBasedOnProperty(customers, "cust_name"), { root: true })
    },
    async GET_ALL_SUPPLIERS({ commit }, utils) {
        const supplierService = new SupplierService(utils)
        let suppliers = await supplierService.getSuppliers(process.env.VUE_APP_API_REQUEST_PERPAGE);
        commit(`${STORE.APP}/SET_ALL_SUPPLIERS`, arrayHelper.sortBasedOnProperty(suppliers,"supp_name"), { root: true })
    },
}