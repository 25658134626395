import AppConstants from "./constants";
import { SNACKBAR } from "./enums/snackbar";
import { HTTPSTATUS, HTTP_METHODS, HTTP_RESPONSE } from "./enums/http";
import { HTTP, HTTP_WITH_FILE, } from "./config/axios";
import { STORE } from "./enums/store";
const successStatus = [
    HTTPSTATUS.SUCCESS,
    HTTPSTATUS.CREATED,
    HTTPSTATUS.NO_CONTENT
];
export default {
    showNotification({ message, statuscode, type }, util) {
        //logout user for 401 response
        // ie; user session expired while making in request.
        if (type === SNACKBAR.ERROR && statuscode === HTTPSTATUS.UNAUTHORIZED) {
            this.logoutUser(util);
        } 
        else if (type === SNACKBAR.ERROR && statuscode === HTTPSTATUS.FORBIDDEN) {
            util.$store.commit(`${STORE.APP}/SHOW_NOTIFICATION`, { message: AppConstants.FORBIDDEN_CONTENT, type })
        }
        else if (type === SNACKBAR.ERROR && statuscode === HTTPSTATUS.INTERNAL_SERVER_ERROR) {
            util.$store.commit(`${STORE.APP}/SHOW_NOTIFICATION`, { message: AppConstants.INTERNAL_SERVER_ERROR, type })
        } 
        else {
            util.$store.commit(`${STORE.APP}/SHOW_NOTIFICATION`, { message, type });
        }
    },
    async getRequest({ url, utils}) {
        try {
            let response = await HTTP[HTTP_METHODS.GET](url);
            return {
                status: successStatus.includes(response?.status) ? HTTP_RESPONSE.SUCCESS : HTTP_RESPONSE.FAILED,
                data: response?.data
            };
        } catch (err) {
            this.showNotification({
                message: err.message,
                statuscode: err.response?.status || 0,
                type: SNACKBAR.ERROR
            }, utils);
            return {
                status: HTTP_RESPONSE.FAILED,
                data: null
            };
        }
    },
    async saveRequest({method, url, payload, utils, fileIncluded = false}) {
        try {
            let response = fileIncluded ? await HTTP_WITH_FILE[method](url, payload) : await HTTP[method](url, payload);
            return {
                status: successStatus.includes(response?.status) ? HTTP_RESPONSE.SUCCESS : HTTP_RESPONSE.FAILED,
                data: response?.data
            };
        } catch (err) {
            this.showNotification({
                message: err.message,
                statuscode: err.response?.status || 0,
                type: SNACKBAR.ERROR
            }, utils);
            return {
                status: HTTP_RESPONSE.FAILED,
                data: null
            };
        }
    },
    async deleteRequest({url, utils, suppress_error = false}) {
        try {
            let response = await HTTP[HTTP_METHODS.DELETE](url);
            return {
                status: successStatus.includes(response?.status) ? HTTP_RESPONSE.SUCCESS : HTTP_RESPONSE.FAILED
            };
        } catch (err) {
            suppress_error || this.showNotification({
                message: err.message,
                statuscode: err.response?.status || 0,
                type: SNACKBAR.ERROR
            }, utils);
            return {
                status: HTTP_RESPONSE.FAILED,
            };
        }
    },
    logoutUser(util) {
        util.$store.commit(`${STORE.AUTH}/UPDATE_USER`, null);
        util.$store.commit(`${STORE.APP}/SHOW_NOTIFICATION`, { message: AppConstants.SESSION_EXPIRED_MESSAGE , type: SNACKBAR.ERROR });
        util.$router.push('/login');
    },
    async loadAllResults({url, utils}) {
        let fullresults = [];
        let resp = {};
        try {
            do {
                resp = await HTTP.get(resp.data?.links?.next ? resp.data?.links?.next : url)
                fullresults = [...fullresults, ...resp?.data?.data];
            } while(resp.data?.links?.next);
        } catch(err) {
            this.showNotification({
                message: err.message,
                statuscode: err.response?.status || 0,
                type: SNACKBAR.ERROR
            }, utils);
        }
        return fullresults;
    }
}