import { STORE } from "../../enums/store";
import arrayHelper from "../../helpers/arrayHelper";
import Auth from "../../services/authService"
import RoleAndPermissionService from "../../services/roleAndPermissionService"
export default {
    async CHECK_USER_STATUS({ commit, dispatch }, utils) {
        const auth = new Auth(utils)
        let status = await auth.isauthorized();
        if(!status){
            commit(`${STORE.AUTH}/UPDATE_USER`, null, { root: true });
            (utils.$route.name !== "Login") && utils.$router.push('/login');
        }  else {
            dispatch(`${STORE.APP}/LOAD_ALL_LOOKUPS`, utils, { root: true });
        }
    }, 
    async GET_CURRENT_USER_PERMISSIONS({ commit }, { utils, id, role_id }) {
        commit(`${STORE.AUTH}/SET_PERMISSION_LOADING`,true, { root: true });
        const roleAndPermission = new RoleAndPermissionService(utils);
        let user_permissions = await roleAndPermission.getUserPermission(id);
        let role_permissions = await roleAndPermission.getRolePermission(role_id);
        commit(`${STORE.AUTH}/SET_PERMISSION_LOADING`,false, { root: true });
        commit(`${STORE.AUTH}/SET_CURRENT_USER_PERMISSIONS`,arrayHelper.removeDuplicates([...user_permissions, ...role_permissions]), { root: true });
    },
}