import common from '../../common';
import { HTTP_METHODS, HTTP_RESPONSE } from '../../enums/http';

export default class VehicleLookup {
    constructor(utils) {
        this.utils = utils;
    }
    async getVehicleManufacturers() {
        let response = await common.getRequest({
            url: `/api/vehicleManufacture`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response?.data?.data?.map(x => ({
                id: x.id,
                manufacture_name: x.manufacture_name
            })) || []; 
        } else {
            return [];
        }
    }
    async getVehicleModels() {
        let response = await common.getRequest({
            url: `/api/vehiclModel`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response?.data?.data?.map(x => ({
                id: x.id,
                model_name: x.model_name,
                lookup_vehicle_manufacture_id: x.lookup_vehicel_manufacture_id
            })) || []; 
        } else {
            return [];
        }
    }
    async getVehicleDerivatives() {
        let response = await common.getRequest({
            url: `/api/vehicleDerivative`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response?.data?.data?.map(x => ({
                id: x.id,
                deivative_name: x.deivative_name,
                lookup_vehicle_model_id: x.lookup_vehicle_model_id
            })) || []; 
        } else {
            return [];
        }
    }
    async getFuelTypes() {
        let response = await common.getRequest({
            url: `/api/vehicleFuelType`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response?.data?.data?.map(x => ({
                id: x.id,
                fuel_type: x.fuel_type
            })) || []; 
        } else {
            return [];
        }
    }
    async getFleetTypes() {
        let response = await common.getRequest({
            url: `/api/vehicleFleetType`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response?.data?.data?.map(x => ({
                id: x.id,
                fleet_type: x.fleet_type
            })) || []; 
        } else {
            return [];
        }
    }
    async getVehicleTypes() {
        let response = await common.getRequest({
            url: `/api/vehicleType`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response?.data?.data?.map(x => ({
                id: x.id,
                vehicle_types: x.vehicle_types
            })) || []; 
        } else {
            return [];
        }
    }
    async getTyreBrandTypes() {
        let response = await common.getRequest({
            url: `/api/getTyre`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response?.data?.data?.map(x => ({
                id: x.id,
                tyre_brand_type: x.trye_brand,
                tyre_size: x.tyre_size,
            })) || []; 
        } else {
            return [];
        }
    }
    async getVehicleGrossWeights() {
        let response = await common.getRequest({
            url: `/api/vehicleGrossWeight`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response?.data?.data?.map(x => ({
                id: x.id,
                vehicle_gross_weight: x.vehicle_gross_weight
            })) || []; 
        } else {
            return [];
        }
    }
    async getVehicleMOTClasses() {
        let response = await common.getRequest({
            url: `/api/getMot`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response?.data?.data?.map(x => ({
                id: x.id,
                mot_class: x.mot_name
            })) || []; 
        } else {
            return [];
        }
    }
    async saveVehicleManufacturer(inputs, method = HTTP_METHODS.POST) {
        let payload = {
            manufacture_name: inputs.manufacture_name
        };
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/addVehicleManufacture` : `/api/updatevehicleManufacture/${inputs.id}`,
            payload,
            utils: this.utils
        });
    }
    async saveVehicleModel(inputs, method = HTTP_METHODS.POST) {
        let payload = {
            model_name: inputs.model_name
        };
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/vehicleModel/${inputs.lookup_vehicle_manufacture_id}/addVehicleModel` : `/api/updatevehicleModel/${inputs.id}`,
            payload,
            utils: this.utils
        });
    } 
    async saveVehicleDerivative(inputs, method = HTTP_METHODS.POST) {
        let payload = {
            deivative_name: inputs.deivative_name
        };
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/vehicleDerivative/${inputs.lookup_vehicle_model_id}/addDerivative` : `/api/updatevehicleDerivative/${inputs.id}`,
            payload,
            utils: this.utils
        });
    } 
    async saveFuelType(inputs, method = HTTP_METHODS.POST) {
        let payload = {
            fuel_type: inputs.fuel_type
        };
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/vehicleFuelType` : `/api/updatevehicleFuelType/${inputs.id}`,
            payload,
            utils: this.utils
        });
    } 
    async saveFleetType(inputs, method = HTTP_METHODS.POST) {
        let payload = {
            fleet_type: inputs.fleet_type
        };
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/vehicleFleetType` : `/api/updatevehicleFleetType/${inputs.id}`,
            payload,
            utils: this.utils
        });
    } 
    async saveVehicleType(inputs, method = HTTP_METHODS.POST) {
        let payload = {
            vehicle_types: inputs.vehicle_types
        };
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/vehicleType` : `/api/updateVehicleType/${inputs.id}`,
            payload,
            utils: this.utils
        });
    } 
    async saveTyreBrandType(inputs, method = HTTP_METHODS.POST) {
        let payload = {
            trye_brand: inputs.tyre_brand_type,
            tyre_size: inputs.tyre_size
        };
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/saveTyre` : `/api/updateTyre/${inputs.id}`,
            payload,
            utils: this.utils
        });
    } 
    async saveGrossWeight(inputs, method = HTTP_METHODS.POST) {
        let payload = {
            vehicle_gross_weight: inputs.vehicle_gross_weight
        };
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/vehicleGrossWeight` : `/api/updatevehicleGrossWeight/${inputs.id}`,
            payload,
            utils: this.utils
        });
    } 
    async saveVehicleMOTClass(inputs, method = HTTP_METHODS.POST) {
        let payload = {
            mot_name: inputs.mot_class
        };
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/saveMot` : `/api/updateMot/${inputs.id}`,
            payload,
            utils: this.utils
        });
    } 
    async deleteManufacturer(inputs) {
        return await common.deleteRequest({
            url: `/api/todoinfutue/${inputs.id}`,
            utils: this.utils
        });
    } 
    async deleteVehicleModel(inputs) {
        return await common.deleteRequest({
            url: `/api/todoinfutue/${inputs.id}`,
            utils: this.utils
        });
    } 
    async deleteVehicleDerivative(inputs) {
        return await common.deleteRequest({
            url: `/api/todoinfutue/${inputs.id}`,
            utils: this.utils
        });
    } 
    async deleteFuelType(inputs) {
        return await common.deleteRequest({
            url: `/api/todoinfutue/${inputs.id}`,
            utils: this.utils
        });
    } 
    async deleteFleetType(inputs) {
        return await common.deleteRequest({
            url: `/api/todoinfutue/${inputs.id}`,
            utils: this.utils
        });
    } 
    async deleteVehicleType(inputs) {
        return await common.deleteRequest({
            url: `/api/todoinfutue/${inputs.id}`,
            utils: this.utils
        });
    } 
    async deleteGrossWeight(inputs) {
        return await common.deleteRequest({
            url: `/api/todoinfutue/${inputs.id}`,
            utils: this.utils
        });
    } 
    async deleteTyreBrandType(inputs) {
        return await common.deleteRequest({
            url: `/api/todoinfutue/${inputs.id}`,
            utils: this.utils
        });
    } 
    async deleteMOTClass(inputs) {
        return await common.deleteRequest({
            url: `/api/todoinfutue/${inputs.id}`,
            utils: this.utils
        });
    } 
}