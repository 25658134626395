import common from '../common';
import objectHelper from '../helpers/objectHelper';
import { HTTP_METHODS, HTTP_RESPONSE } from '../enums/http';
import { STORE } from '../enums/store';

export default class CustomerService {
    constructor(utils) {
        this.utils = utils;
    }
    async getCustomers(per_page) {
        let response = await common.getRequest({
            url: `/api/customer/${per_page}`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response.data.map(x => ({
                id: x.id,
                cust_name: x.cust_name,
                location: x.lookup_location_id,
                cust_address: x.cust_address,
                cust_address1: x.cust_address1,
                cust_address2: x.cust_address2,
                cust_address3: x.cust_address3,
                cust_address4: x.cust_address4,
                pin_code: x.pin_code,
                cust_contact_number: x.cust_contact_number,
                cust_email: x.cust_email,
                is_active: x.is_active ? true : false
            })); 
        } else {
            return [];
        }
    }
    
    async getCustomerByStringMatch(text) {
        // let response = await common.getRequest({
        //     url: `/api/customersearch?text=${text}`,
        //     utils: this.utils
        // });
        // if(response.status === HTTP_RESPONSE.SUCCESS) {
        //     return response.data.map(x => ({
        //         id: x.id,
        //         cust_name: x.cust_name,
        //         location: x.lookup_location_id,
        //         cust_address: x.cust_address,
        //         cust_address1: x.cust_address1,
        //         cust_address2: x.cust_address2,
        //         pin_code: x.pin_code,
        //         cust_contact_number: x.cust_contact_number,
        //         cust_email: x.cust_email,
        //         is_active: x.is_active ? true : false
        //     })); 
        // } else {
        //     return [];
        // }
        return this.utils.$store.state[STORE.APP].customers.filter(x => x.cust_name?.toLowerCase()?.indexOf(text.toLowerCase()) !== -1) || [];
    }
    async getCustomerDetails(cust_id) {
        let response = await common.getRequest({
            url: `/api/customerDetails/${cust_id}`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response.data.map(x => ({
                id: x.id,
                priority: x.priority,
                name: x.name,
                job_title: x.job_title,
                location: x.location,
                phone: x.phone,
                email: x.email,
                customer_id: x.customer_id,
                address: x.address,
                address1: x.address1,
                address2: x.address2,
                address3: x.address3,
                address4: x.address4,
                pin_code: x.pin_code,
            })); 
        } else {
            return [];
        }
    }
    async getCustomerById(cust_id) {
        // let response = await common.getRequest({
        //     url: `/api/customerByid/${cust_id}`,
        //     utils: this.utils
        // });
        // if(response.status === HTTP_RESPONSE.SUCCESS) {
        //     if(response.data.length) {
        //         return {
        //             id: response.data[0].id,
        //             cust_name: response.data[0].cust_name,
        //             location: response.data[0].lookup_location_id,
        //             cust_address: response.data[0].cust_address,
        //             cust_address1: response.data[0].cust_address1,
        //             cust_address2: response.data[0].cust_address2,
        //             pin_code: response.data[0].pin_code,
        //             cust_contact_number: response.data[0].cust_contact_number,
        //             cust_email: response.data[0].cust_email,
        //             is_active: response.data[0].is_active ? true : false
        //         };
        //     } 
        //     return null;
        // } else {
        //     return null;
        // }
        return this.utils.$store.state[STORE.APP].customers.find(x => x.id === cust_id) || null;
    }
    async saveCustomer(inputs, method = HTTP_METHODS.POST) {
        let payload = Object.assign({},{
            ...inputs,
            lookup_location_id: inputs.location
        });
        delete payload.customer_details;
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/customer` : `/api/updatecustomer/${inputs.id}`,
            payload,
            utils: this.utils
        });
    }
    async addCustomerDetails(customer_details, customer_id) {
        for (let i = 0; i < customer_details.length; i++) {
            let payload = {
                ...customer_details[i],
            };
            await common.saveRequest({
                method: HTTP_METHODS.POST,
                url: `/api/customer/${customer_id}/customerDetails`,
                payload,
                utils: this.utils
            });
        }
    }
    async updateCustomerDetails(customer_details) {
        for (let i = 0; i < customer_details.length; i++) {
            let payload = {
                ...customer_details[i],
            };
            await common.saveRequest({
                method: HTTP_METHODS.PUT,
                url: `/api/updateCustomerDeatils/${payload.id}`,
                payload,
                utils: this.utils
            });
        }
    }
    async saveCustomerDetails(customer, customer_id, old_customer_details= null) {
        let { added, updated } = objectHelper.difference(customer.customer_details, old_customer_details);
        added.length && await this.addCustomerDetails(added, customer_id);
        updated.length && await this.updateCustomerDetails(updated);
        return {
            status: HTTP_RESPONSE.SUCCESS
        };
    }
    async deleteCustomerDetails(customer_details) {
        return await common.deleteRequest({
            url: `/api/removeCustomerDetails/${customer_details.id}`,
            utils: this.utils
        });
    }
    async deleteCustomer(customer) {
        return await common.deleteRequest({
            url: `/api/removeCustomer/${customer.id}`,
            utils: this.utils
        });
    }
}