import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

const state = {
    jtc_categories: [],
    job_types: [],
    vehicle_service_items: [],
    service_basises: ["Weeks", "Miles"]
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}