import { STORE } from '../../../enums/store';
import EquipmentLookupService from '../../../services/lookups/equipmentLookupService';
export default {
    async LOAD_EQUIPMENT_LOOKUPS({ dispatch }, utils) {
        dispatch(`${STORE.EQUIPMENT_LOOKUP}/LOAD_EQUIPMENT_MANUFACTURERS`, utils, { root: true });
        dispatch(`${STORE.EQUIPMENT_LOOKUP}/LOAD_EQUIPMENT_TYPES`, utils, { root: true });
        dispatch(`${STORE.EQUIPMENT_LOOKUP}/LOAD_EQUIPMENT_MODELS`, utils, { root: true });
    },
    async LOAD_EQUIPMENT_MANUFACTURERS({ commit }, utils) {
        const equipmentLookupService = new EquipmentLookupService(utils);
        equipmentLookupService.getManufacturers().then(resp => {
            commit(`${STORE.EQUIPMENT_LOOKUP}/SET_EQUIPMENT_MANUFACTURERS_LOOKUP`, resp, { root: true });
        }); 
    },
    async LOAD_EQUIPMENT_TYPES({ commit }, utils) {
        const equipmentLookupService = new EquipmentLookupService(utils);
        equipmentLookupService.getEquipmentTypes().then(resp => {
            commit(`${STORE.EQUIPMENT_LOOKUP}/SET_EQUIPMENT_TYPES_LOOKUP`, resp, { root: true });
        });
    },
    async LOAD_EQUIPMENT_MODELS({ commit }, utils) {
        const equipmentLookupService = new EquipmentLookupService(utils);
        equipmentLookupService.getEquipmentModels().then(resp => {
            commit(`${STORE.EQUIPMENT_LOOKUP}/SET_EQUIPMENT_MODELS_LOOKUP`, resp, { root: true });
        });
    },
}