import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

const state = {
    is_vehicle_lookup_loaded: false,
    //vehicle tab
    vehicle_manufacturer: [],
    vehicle_model: [],
    vehicle_derivative: [],
    fuel_types: [],
    fleet_types: [],
    vehicle_types: [],
    tyre_brand_types: [],
    vehicle_gross_weight: [],
    mot_classes: []
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}