import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

const state = {
    is_new_job: false,
    selected_vehicle: null,
    selected_job_id: "",
    selected_job_trackers_id: "",
    completed_jobs: [],
    comments: [],
    job_statuses: [],
    selected_job: {
        status: "",
        vor: false,
        po_no: "",
        mileage: "",
        work_progress_status: null,
        last_recorded_miles: 0,
        next_due_date: "",
        date_raised: null,
        date_completed: null,
        supplier_id: null,
        files: [],
        documents_path: [],
        original_documents_path: [],
        document_id: null
    },
    selected_job_items: [],
    readonly: false,
    is_incident: false
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}