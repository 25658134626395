import common from '../../common';
import { HTTP_METHODS, HTTP_RESPONSE } from '../../enums/http';

export default class ConversionLookup {
    constructor(utils) {
        this.utils = utils;
    }
    async getVehicleConverters() {
        let response = await common.getRequest({
            url: `/api/getConverter`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response?.data?.data?.map(x => ({
                id: x.id,
                converter: x.name
            })) || []; 
        } else {
            return [];
        }
    }
    async getVehicleWarrantyExcludedItems() {
        let response = await common.getRequest({
            url: `/api/getwarrantyExclusion`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response.data?.map(x => ({
                id: x.id,
                warranty: x.name
            })) || []; 
        } else {
            return [];
        }
    }
    async saveVehicleConverter(inputs, method = HTTP_METHODS.POST) {
        let payload = {
            name: inputs.converter
        };
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/addConverter` : `/api/updateConverter/${inputs.id}`,
            payload,
            utils: this.utils
        });
    } 
    async saveVehicleWarrantyExclusion(inputs, method = HTTP_METHODS.POST) {
        let payload = {
            name: inputs.warranty
        };
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/savewarrantyExclusion` : `/api/updatewarrantyExclusion/${inputs.id}`,
            payload,
            utils: this.utils
        });
    } 
    async deleteVehicleConverter(inputs) {
        return await common.deleteRequest({
            url: `/api/removeConverter/${inputs.id}`,
            utils: this.utils
        });
    } 
    async deleteVehicleWarrantyExclusion(inputs) {
        return await common.deleteRequest({
            url: `/api/removewarrantyExclusion/${inputs.id}`,
            utils: this.utils
        });
    } 
}