import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

const state = {
    title: "",
    notification: null,
    overlay_loading: null,
    suppliers: [],
    customers: [],
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}