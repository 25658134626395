export default {
    SET_IS_VEHICLE_LOOKUP_LOADED(state, payload) {
        state.is_vehicle_lookup_loaded = payload;
    },
    SET_VEHICLE_MANUFACTURER_LOOKUP(state, payload) {
        state.vehicle_manufacturer = payload;
    },
    SET_VEHICLE_MODEL_LOOKUP(state, payload) {
        state.vehicle_model = payload;
    },
    SET_VEHICLE_DERIVATIVE_LOOKUP(state, payload) {
        state.vehicle_derivative = payload;
    },
    SET_FUEL_TYPES(state, payload) {
        state.fuel_types = payload;
    },
    SET_FLEET_TYPES(state, payload) {
        state.fleet_types = payload;
    },
    SET_VEHICLE_TYPES(state, payload) {
        state.vehicle_types = payload;
    },
    SET_TYRE_BRAND_TYPES(state, payload) {
        state.tyre_brand_types = payload;
    },
    SET_GROSS_VEHICLE_WEIGHTS(state, payload) {
        state.gross_vehicle_weights = payload;
    },
    SET_MOT_CLASSES(state, payload) {
        state.mot_classes = payload;
    }
}