import common from '../../common';
import { HTTP_RESPONSE } from '../../enums/http';

export default class JobTrackerLookup {
    constructor(utils) {
        this.utils = utils;
    }
    async getWorkProgressStatusList() {
        let response = await common.getRequest({
            url: `/api/getLookupWorkProgress`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response?.data?.map(x => ({
                id: x.id,
                name: x.name,
                order: x.order
            })) || []; 
        } else {
            return [];
        }
    }
}