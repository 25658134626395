<template>
  <v-btn
    :outlined="outlined"
    :block="block"
    :disabled="disabled"
    color="primary"
    :small="$vuetify.breakpoint.smAndDown"
    @click="click">
        <slot></slot>
    </v-btn>
</template>

<script>
export default {
    props: {
        outlined: {
            type: Boolean,
            default: false
        },
        block: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        click(e) {
            this.$emit('click', e);
        }
    }
}
</script>

<style>

</style>