import AppConstants from "../constants";
export default {
    data() {
        const mandatory = [v => !!v || AppConstants.MANDATORY_FIELD_ERROR];
        const mandatory_text = [v => v && (v.trim() != '') || AppConstants.MANDATORY_FIELD_ERROR];
        const number_validation = [
            v => !isNaN(v) || AppConstants.INVALID_NUMBER_FIELD_ERROR,
        ];
        const array_required = [
            v => !(v instanceof Array && v.length == 0) || AppConstants.MANDATORY_FIELD_ERROR
        ]
        return {
            validationRules: {
                required: mandatory,
                required_text: [
                    ...mandatory,
                    ...mandatory_text
                ],
                array_required: array_required,
                number: number_validation,
                phone: [
                    v => (v?.length || 0) <= 15 || 'Max 15 characters',
                ],
                mandatory_number: [
                    ...mandatory,
                    ...number_validation
                ],
                email: [
                    ...mandatory,
                    (v) => /.+@.+\..+/.test(v) || AppConstants.INVALID_EMAIL,
                ],
                password: [
                    ...mandatory_text, 
                    v => (v?.length || 0) >= 6 || 'Minimum 6 characters',
                ]
            }
        }
    }
}