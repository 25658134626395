<template>
  <PreLoginLayout :title="showLoginForm ? 'Sign in' : 'Forgot password'">
    <v-form
      v-if="showLoginForm"
      ref="loginform"
      v-model="loginFormValid"
      lazy-validation
      @submit.prevent="login"
    >
      <v-card-text>
        <BaseInput
          label="E-mail"
          v-model="email"
          prepend_icon="mdi-email"
          type="email"
          :rules="validationRules.email"
          required
        />
        <BaseInput
          label="Password"
          v-model="password"
          :type="showpassword ? 'text' : 'password'"
          :rules="validationRules.password"
          :append_icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
          autocomplete="on"
          prepend_icon="mdi-lock"
          required
          @eventAppendIconClicked="showpassword = !showpassword"
        />
      </v-card-text>
      <v-card-actions class="px-4">
        <v-spacer></v-spacer>
        <v-btn
          block
          color="primary"
          :disabled="!loginFormValid"
          :loading="loading"
          type="submit"
          >Sign in</v-btn
        >
      </v-card-actions>
      <div
        class="text-center link mt-4 primary--text"
        @click="showForgotPasswordScreen"
      >
        Forgot password?
      </div>
    </v-form>
    <v-form
      v-else
      ref="forgotPasswordForm"
      v-model="forgotPasswordFormValid"
      lazy-validation
      @submit.prevent="forgotPassword"
    >
      <v-card-text>
        <v-alert outlined :type="alertType" text>
          {{ alertMessage }}
        </v-alert>
        <BaseInput
          label="E-mail"
          v-if="alertType != ALERT_TYPE.SUCCESS"
          v-model="forgotPasswordEmail"
          prepend_icon="mdi-email"
          type="email"
          :rules="validationRules.email"
          required
        />
      </v-card-text>
      <v-card-actions class="d-flex flex-column px-4">
        <v-btn
          block
          v-if="alertType != ALERT_TYPE.SUCCESS"
          color="primary"
          :disabled="!forgotPasswordFormValid"
          :loading="loading"
          type="submit"
          >Reset Password</v-btn
        >
        <BaseButton block outlined class="mt-3 ml-0" @click="cancel">
          Back to Login
        </BaseButton>
      </v-card-actions>
    </v-form>
  </PreLoginLayout>
</template>

<script>
import PreLoginLayout from "../layouts/PreLoginLayout.vue";
import validationMixin from "../mixins/validationMixin";
import AuthService from "../services/authService";
import { ALERT_TYPE } from '../enums/status';
import AppConstants from "../constants";

export default {
  mixins: [validationMixin],
  components: {
    PreLoginLayout,
  },
  data: () => ({
    email: "",
    password: "",
    showpassword: false,
    loading: false,
    loginFormValid: false,
    forgotPasswordFormValid: false,
    showLoginForm: true,
    forgotPasswordEmail: "",
    alertType: "",
    alertMessage: "",
    ALERT_TYPE
  }),
  methods: {
    async login() {
      if (this.$refs.loginform.validate()) {
        let authService = new AuthService(this.$utils);
        this.loading = true;
        await authService.login(this.email, this.password, this.$utils);
        this.loading = false;
      }
    },
    showForgotPasswordScreen() {
      this.showLoginForm = false;
      this.forgotPasswordEmail = "";
      this.alertMessage = AppConstants.FORGOT_PASSWORD_INFO;
      this.alertType = ALERT_TYPE.WARNING;
      this.$nextTick(() => this.$refs.forgotPasswordForm.reset());
    },
    cancel() {
      this.showLoginForm = true;
      this.email = "";
      this.password = "";
      this.$nextTick(() => this.$refs.loginform.reset());
    },
    async forgotPassword() {
      if (this.$refs.forgotPasswordForm.validate()) {
        let authService = new AuthService(this.$utils);
        this.loading = true;
        let resp = await authService.forgotPassword(this.forgotPasswordEmail);
        this.alertMessage = resp.data.message;
        switch(resp.data.message) {
          case AppConstants.FORGOT_PASSWORD_EMAIL_SUCCESS: {
            this.alertType = ALERT_TYPE.SUCCESS;
            this.$toast.success(resp.data.message);
            break;
          }
          default: 
            this.alertType = ALERT_TYPE.ERROR;
            this.$toast.error(resp.data.message);
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.link:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>