export default {
    SET_IS_EQUIPMENT_LOOKUP_LOADED(state, payload) {
        state.is_equipment_lookup_loaded = payload;
    },
    SET_EQUIPMENT_MANUFACTURERS_LOOKUP(state, payload) {
        state.equipment_manufacturers = payload;
    },
    SET_EQUIPMENT_TYPES_LOOKUP(state, payload) {
        state.equipment_types = payload;
    },
    SET_EQUIPMENT_MODELS_LOOKUP(state, payload) {
        state.equipment_models = payload;
    }
}