export default {
    authorized(state) {
        return (state.user && Object.keys(state.user).length > 0);
    } ,
    role(state){
        return state.user?.role || '';
    },
    hasPermission(state) {
        return (permission) => state.permissions.includes(permission);
    } 
}