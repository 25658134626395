import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

const state = {
    user: null,
    permissions: [],
    permission_loading: false
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}