export default {
    SET_JTC_CATEGORIES(state, payload) {
        state.jtc_categories = payload;
    },
    SET_JOB_TYPES(state, payload) {
        state.job_types = payload;
    },
    SET_VEHICLE_SERVICE_ITEMS(state, payload) {
        state.vehicle_service_items = payload;
    },
}