<template>
  <v-menu 
    offset-y 
    :nudge-width="250"
    :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-avatar size="42px" v-bind="attrs" v-on="on">
        <BaseDummyAvatar :size="42" :name="user.name" />
      </v-avatar>
    </template>
    <v-card class="pa-2 relative dark-border">
      <v-col class="d-flex justify-center align-center flex-column bg-gradient-primary">
        <v-avatar size="70" class="profile-img">
          <BaseDummyAvatar :size="70" :name="user.name" />
        </v-avatar>
        <h3 class="pt-3 primary--text">{{loggedin_username}}</h3>
        <div class="caption text--secondary">{{loggedin_email}}</div>
        <div class="caption text--secondary text-right">version: {{appVersion}}</div>
      </v-col>
      <v-list-item class="px-1">
        <v-list-item-avatar>
          <v-avatar size="35">
            <v-img src="/icons/theme.png"></v-img>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            <ThemeToggler :label="'Toggle Theme'"/>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="px-1" @click="$emit('eventLogout')">
        <v-list-item-avatar>
          <v-avatar size="35">
            <v-img src="/icons/logout.png"></v-img>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            Sign out
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-menu>
</template>

<script>
import ThemeToggler from './DarkModeToggler.vue'
import BaseDummyAvatar from "../BaseDummyAvatar.vue";

export default {
  data: () => ({}),
  props: ["user", "appVersion"],
  components: {
    ThemeToggler,
    BaseDummyAvatar
  },
  computed: {
    loggedin_username() {
      return this.user ? this.user.name : "";
    },
    loggedin_email() {
      return this.user ? this.user.email : "";
    },
  }
};
</script>

<style lang="scss">
.profile-img {
  border: 5px solid $shaded-white;
  box-shadow: 0px 2px 4px 0px rgb(0 174 239 / 28%);
}
.bg-gradient-primary {
  background: linear-gradient(to bottom, $app-base-color 0%, $white 100%);
}
.theme--dark {
  .bg-gradient-primary {
    background: #121212;
  }
}
</style>