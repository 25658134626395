export default {
    SET_FINANCIAL_RNM_BUDGET_YEARS(state, payload) {
        state.annual_rnm_budget_years = payload;
    },
    SET_FINANCIAL_TYRE_BUDGET_YEARS(state, payload) {
        state.annual_tyre_budget_years = payload;
    },
    SET_FINANCIAL_PAYMENT_PROFILES(state, payload) {
        state.payment_profiles = payload;
    },
    SET_FINANCIAL_PERIODS(state, payload) {
        state.periods = payload;
    }
}