export default {
    //accepts DD/MM/YYYY  or DD/MM/YY and validates 
    validateDate(value) {
        if(!value) return true;
        const pattern = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/([0-9]{4})$/;
        return pattern.test(value);
    },
    //accepts DD/MM/YYYY HH:SS   or DD/MM/YY and validates 
    validateDateTime(value) {
        if(!value) return true;
        const pattern = /^(0?[1-9]|[1-2][0-9]|3[0-1])\/(0?[1-9]|1[0-2])\/\d{4}\s+(0?[1-9]|1[0-2]):([0-5][0-9])\s+(AM|PM)$/i;
        return pattern.test(value);
    },
    //accepts DD/MM/YYYY and converts to YYYY-MM-DD
    formatDate(date) {
        if (!date) return null
        const [day, month, year] = date.split('/')
        return `${year}-${month}-${day}`;
    },
    //accepts DD/MM/YYYY and converts to YYYY-MM-DD HH:MM:SS
    formatDateTime(datetime) {
        if (!datetime) return null;
        let date = datetime.split(" ")[0];
        let time = datetime.split(" ")?.[1];
        let ampm = datetime.split(" ")?.[2];
        const [day, month, year] = date.split('/')
        return `${year}-${month}-${day} ${time ? this.convertTime(time + ' ' + ampm) : '00:00:00'}`;
    },
    // Accepts HH:MM AM/PM and converts to HH:MM:SS
    convertTime(time) {
        var hours = Number(time.match(/^(\d+)/)[1]);
        var minutes = Number(time.match(/:(\d+)/)[1]);
        var AMPM = time.match(/\s(.*)$/)[1];
        if (AMPM == "PM" && hours < 12) hours = hours + 12;
        if (AMPM == "AM" && hours == 12) hours = hours - 12;
        var sHours = hours.toString();
        var sMinutes = minutes.toString();
        if (hours < 10) sHours = "0" + sHours;
        if (minutes < 10) sMinutes = "0" + sMinutes;
        return sHours + ":" + sMinutes + ":00";
    },
    // Accepts HH:MM:SS and converts to HH:MM AM/PM
    reverseTime(time) {
        var timeArr = time.split(':');
        var hours = parseInt(timeArr[0]);
        var minutes = parseInt(timeArr[1]);
        var AMPM = "AM";
        if (hours >= 12) {
          AMPM = "PM";
          if (hours > 12) {
            hours = hours - 12;
          }
        }
        if (hours == 0) {
          hours = 12;
        }
        var sHours = hours.toString();
        var sMinutes = minutes.toString();
        if (hours < 10) {
          sHours = "0" + sHours;
        }
        if (minutes < 10) {
          sMinutes = "0" + sMinutes;
        }
        return sHours + ":" + sMinutes + " " + AMPM;
    },
    //accepts YYYY-MM-DD:TIME and converts to  DD/MM/YYYY
    formatDateReverse(date) {
        if (!date) return null
        const [year, month, day] = date.split(' ')[0].split('-')
        return `${day}/${month}/${year}`
    },
    //accepts YYYY-MM-DD HH:MM:SS and converts to  DD/MM/YYYY HH:MM AM/PM
    formatDateTimeReverse(date) {
        if (!date) return null
        const [year, month, day] = date.split(' ')[0].split('-')
        let time = date.split(' ')?.[1];
        return `${day}/${month}/${year} ${time ? this.reverseTime(time) : '00:00 AM'}`
    },
    //accepts DD/MM/YYYY and returns YYYY-MM-DD
    calenderFormatedDate(date) {
        if (!date) return null;
        const [day, month, year] = date.split('/')
        return `${year}-${month}-${day}`;
    },
    //accepts DD/MM/YYYY HH:MM:SS  and returns DD/MM/YYYY HH:MM AM/PM
    calenderFormatedDisplayDateTime(datetime) {
        if(!datetime) return null;
        let date = datetime.split(' ')?.[0];
        let time = datetime.split(' ')?.[1];
        return `${date} ${time ? this.reverseTime(time) : '00:00 AM'}`
    },
    //Accepts DD/MM/YYYY HH:MM:SS and returns YYYY-MM-DD HH:MM:SS
    calenderFormatedDateTime(datetime) {
        if(!datetime) return null;
        let date = datetime.split(' ')?.[0];
        let time = datetime.split(' ')?.[1];
        const [day, month, year] = date.split('/')
        return `${year}-${month}-${day} ${time? time : '00:00:00'}`
    },
    //accepts YYYY-MM-DD and returns DD/MM/YYYY
    calenderReverseFormatedDate(date) {
        if (!date) return null;
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
    },
    //accepts new Date() Object and converts to DD/MM/YYYY
    jsDateFormatter(date) {
        if (!date) return null
        const day = this.pad(date.getDate());
        const month = this.pad(date.getMonth() + 1);
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    },
    //accepts DD/MM/YYYY and returnd new Date()
    jsDateReverseFormatter(date) {
        if (!date) return null
        const [day, month, year] = date.split('/').map(Number);
        // Create a new Date object using the extracted values
        // Month is 0-based in JavaScript Date, so we subtract 1
        return new Date(year, month - 1, day);
    },
    // accepts new Date() Object  YYYY-MM-DD HH:MM:SS
    jsDateTimeFormatter(date) {
        if (!date) return null
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
    },
    //accepts DD/MM/YYYY and add months to it
    addMonths(date, month) {
        if(!date) return null;
        let formatted_date = new Date(this.formatDate(date));
        formatted_date.setMonth(formatted_date.getMonth() + Number.parseInt(month));
        Number.parseInt(month) && formatted_date.setDate(formatted_date.getDate() - 1);
        let converted_date = new Date(formatted_date);
        return this.jsDateFormatter(converted_date);
    },
    //accepts DD/MM/YYYY and add weeks to it
    addWeeks(date, month) {
        if(!date) return null;
        let formatted_date = new Date(this.formatDate(date));
        formatted_date.setDate(formatted_date.getDate() + Number.parseInt(month) * 7);
        Number.parseInt(month) && formatted_date.setDate(formatted_date.getDate() - 1);
        let converted_date = new Date(formatted_date);
        return this.jsDateFormatter(converted_date);
    },
    //accepts from(YYYY-MM-DD) & to(YYYY-MM-DD) returnd date difference between both
    // 0 => same, +ve if to > from , -ve if to < from 
    dateDifference(from, to) {
        const formatted_fromdate = new Date(from);
        const formatted_todate = new Date(to);
        const diffTime = formatted_todate - formatted_fromdate;
        // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffTime;
    },
    getTodaysDate() {
        return this.jsDateFormatter(new Date());
    },
    getTodaysDateTime() {
        return this.jsDateTimeFormatter(new Date());
    },
    getTodaysFormattedDateTime() {
        return this.calenderFormatedDisplayDateTime(this.jsDateTimeFormatter(this.roundHours(new Date())));
    },
    //Accepts New Date and convers to HH+1:00:00
    roundHours(date) {
        date.setMinutes(0);
        date.setSeconds(0);
        var roundedHours = Math.ceil(date.getHours() + 1 + date.getMinutes() / 60);
        date.setHours(roundedHours);
        return date;
    },
    pad(val) { 
        return (val < 10) ? '0' + val : val; 
    }
}