import common from '../../common';
import { HTTP_METHODS, HTTP_RESPONSE } from '../../enums/http';

export default class EquipmentLookup {
    constructor(utils) {
        this.utils = utils;
    }
    async getManufacturers() {
        let response = await common.getRequest({
            url: `/api/lookupEquipmentManufacture`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response?.data?.data?.map(x => ({
                id: x.id,
                manufacture_name: x.manufacture_name
            })) || []; 
        } else {
            return [];
        }
    }
    async getEquipmentTypes() {
        let response = await common.getRequest({
            url: `/api/lookupEquipmentType`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response?.data?.data?.map(x => ({
                id: x.id,
                equipment_type_name: x.equipment_type_name,
                manufacture_ids : x.manufacture_ids || []
            })) || []; 
        } else {
            return [];
        }
    }
    async getEquipmentModels() {
        let response = await common.getRequest({
            url: `/api/getEquipmentModel`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response?.data?.data?.map(x => ({
                id: x.id,
                model_name: x.model_name,
                equipment_type_id: x.equipment_type_id,
                equipment_manufacture_id: x.equipment_manufacture_id
            })) || []; 
        } else {
            return [];
        }
    }
    async saveManufacturer(inputs, method = HTTP_METHODS.POST) {
        let payload = {
            manufacture_name: inputs.manufacture_name
        };
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/addEquipmentManufacture` : `/api/updateEquipmentManufacture/${inputs.id}`,
            payload,
            utils: this.utils
        });
    } 
    async saveEquipmentType(inputs, method = HTTP_METHODS.POST) {
        let payload = {
            equipment_type_name: inputs.equipment_type_name,
            manufacture_ids: inputs.manufacture_ids ? inputs.manufacture_ids.map(x => x.id) : []
        };
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/addEquipmentType` : `/api/updateEquipmentType/${inputs.id}`,
            payload,
            utils: this.utils
        });
    } 
    async saveEquipmentModel(inputs, method = HTTP_METHODS.POST) {
        let payload = {
            model_name: inputs.model_name,
            equipment_type_id : inputs.equipment_type_id, 
            equipment_manufacture_id : inputs.equipment_manufacture_id 
        };
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/addEquipmentModel` : `/api/updateEquipmentModel/${inputs.id}`,
            payload,
            utils: this.utils
        });
    } 
    async deleteManufacturer(inputs) {
        return await common.deleteRequest({
            url: `/api/removeEquipmentManufacture/${inputs.id}`,
            utils: this.utils
        });
    } 
    async deleteEquipmentType(inputs) {
        return await common.deleteRequest({
            url: `/api/removeEquipmentType/${inputs.id}`,
            utils: this.utils
        });
    } 
    async deleteEquipmentModel(inputs) {
        return await common.deleteRequest({
            url: `/api/removeEquipmentModel/${inputs.id}`,
            utils: this.utils
        });
    } 
}