import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

const state = {
    converters: [],
    warranty_excluded_items: []
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}