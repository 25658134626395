<template>
  <div class="relative">
    <BaseInput
      :label="label"
      hint="DD/MM/YYYY format"
      :rules="daterule"
      :inlineLabel="inlineLabel"
      :required="mandatory"
      :disabled="disabled"
      :readonly="readonly"
      v-model="dateTextBox"
      @blur="updateDate(dateTextBox)"></BaseInput>
    <v-menu
      v-model="dateMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      :disabled="disabled || readonly"
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <div class="calender-icon" v-on="on">
          <v-icon>mdi-calendar</v-icon>
        </div>
      </template>
      <v-date-picker
        v-model="dateValue"
        color="primary"
        @change="updateDate(dateValue, true)"
        :readonly="readonly"
        :min="mindate"
        :max="maxdate"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import AppConstants from "../constants";
import validationMixin from "../mixins/validationMixin";
import datehelper from "../helpers/dateHelper";
export default {
  mixins:[validationMixin],
  data() {
    return {
      dateTextBox: "",
      dateValue: "",
      dateMenu: false,
      daterule: [
          value => !!value || (this.required || AppConstants.MANDATORY_FIELD_ERROR),
          value => datehelper.validateDate(value) || AppConstants.INVALID_DATE
      ],
      mindate: "",
      maxdate: ""
    };
  },
  props: {
    value: {
      default: "",
    },
    label: {
      default: "Date",
    },
    required: {
      default: true
    },
    mandatory: {
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      default: ""
    },
    max: {
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    },
    inlineLabel: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateDate(val, fromcalender = false) {
      fromcalender && (val = datehelper.calenderReverseFormatedDate(val));
      this.$emit("input", datehelper.validateDate(val) ? val : null);
      this.dateMenu = false;
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.dateTextBox = datehelper.validateDate(val) ? val : datehelper.calenderReverseFormatedDate(val);
        this.dateValue = datehelper.calenderFormatedDate(this.dateTextBox);
      },
      immediate: true,
    },
    min: {
      handler: function(val) {
        if(val) {
          this.mindate = datehelper.calenderFormatedDate(val);
          const datediff = datehelper.dateDifference(this.mindate, this.dateValue);
          if(datediff < 0) {
            this.dateValue = "";
            this.dateTextBox = "";
          }
        }
      },
      immediate: true
    },
    max: {
      handler: function(val) {
        if(val) {
          this.maxdate = datehelper.calenderFormatedDate(val);
          const datediff = datehelper.dateDifference(this.maxdate, this.dateValue);
          if(datediff > 0) {
            this.dateValue = "";
            this.dateTextBox = "";
          }
        }
      },
      immediate: true
    }
  },
};
</script>
<style>
.calender-icon {
  position: absolute;
  right: 10px;
  bottom: 34px;
}
</style>