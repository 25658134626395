import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

const state = {
    is_equipment_lookup_loaded: false,

    equipment_manufacturers: [],
    equipment_types: [],
    equipment_models: []   
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}