import { STORE } from '../../../enums/store';
import JobTrackerLookupService from '../../../services/lookups/jobTrackerLookupService';
export default {
    LOAD_JOBTRACKER_LOOKUPS({ dispatch }, utils) {
        dispatch(`${STORE.JOBTRACKER_LOOKUP}/LOAD_WORK_PROGRESS_STATUS_LIST`, utils, { root: true });      
    },
    LOAD_WORK_PROGRESS_STATUS_LIST({ commit }, utils) {
        const jobTrackerLookupService = new JobTrackerLookupService(utils);
        jobTrackerLookupService.getWorkProgressStatusList().then(resp => {
            commit(`${STORE.JOBTRACKER_LOOKUP}/SET_WORK_PROGRESS_STATUS_LIST`, resp, { root: true });
        }); 
    }
}