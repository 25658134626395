<template>
  <v-app>
    <transition name="zoomout">
      <SplashScreen v-if="loading_splash" />
    </transition>
    <v-navigation-drawer v-if="authorized" v-model="drawer" app class="border-base">
      <SidePanel />
    </v-navigation-drawer>

    <v-app-bar v-if="authorized" app color="primary" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="cursor-pointer">{{ title }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <Avatar :user="user" :appVersion="appVersion" @eventLogout="logout"/>
      
    </v-app-bar>

    <v-main class="bg-app-background">
      <slot></slot>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Auth from "../services/authService";
import SidePanel from "../components/app/AppSidePanel.vue";
import Avatar from "../components/app/UserAvatar.vue";
import SplashScreen from "../components/app/SplashScreen.vue";
import { STORE } from '../enums/store';
import {version} from '../../package';

export default {
  data: () => ({
    loading_splash: true,
    drawer: null,
    appVersion: version,
  }),
  computed: {
    ...mapGetters(STORE.AUTH, ["authorized"]),
    ...mapState({
      user: state => state[STORE.AUTH].user,
      title: state => state[STORE.APP].title
    }),
  },
  components: {
    SidePanel,
    Avatar,
    SplashScreen
  },
  mounted() {
    setTimeout(() => {
      this.loading_splash = false;
    }, 4500)
  },  
  methods: {
    toggleDrawer() {
      this.sidepanel_toggler = !this.sidepanel_toggler;
    },
    async logout() {
      const authService = new Auth(this.$utils);
      const logout_status = await authService.logout();
      logout_status && this.$router.push('/login');
    },
  },
};
</script>
<style scoped>
.zoomout-leave-active {
    animation: zoomout 0.8s reverse;
} 
@keyframes zoomout {
  0% {
    transform: scale(0);
    background: transparent;
  }
  50% {
    transform: scale(1.5);
    background: #ffffff5d;
  }
  100% {
    transform: scale(1);
  }
}
</style>