import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import store from "../store/index.js";
import AppConstants from '../constants'
import { SNACKBAR } from '../enums/snackbar';
import { STORE } from '../enums/store';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    alias: '/login'
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component:  () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue'),
   
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: { 
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
  },
  {
    path: '/jobtracker',
    name: 'Job Tracker',
    meta: { 
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "jobtracker" */ '../views/JobTracker.vue')
  },
  {
    path: '/incidents',
    name: 'Incident Management',
    meta: { 
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "incidents" */ '../views/Incidents.vue')
  },
  {
    path: '/vehicles',
    name: 'Vehicles',
    meta: { 
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "vehicles" */ '../views/Vehicles.vue')
  },
  {
    path: '/settings/customers',
    name: 'Customers',
    meta: { 
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "customers" */ '../views/settings/Customers.vue')
  },
  {
    path: '/settings/suppliers',
    name: 'Suppliers',
    meta: { 
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "Suppliers" */ '../views/settings/Suppliers.vue')
  },
  {
    path: '/settings/lookups/equipments',
    name: 'Equipments Lookups',
    meta: { 
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "equipmentlookup" */ '../views/settings/lookups/Equipments.vue')
  },
  {
    path: '/settings/lookups/vehicles',
    name: 'Vehicles Lookups',
    meta: { 
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "vehicleslookup" */ '../views/settings/lookups/VehicleLookups.vue')
  },
  {
    path: '/settings/lookups/jobtracker',
    name: 'Job Tracker Lookups',
    meta: { 
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "jobtrackerlookup" */ '../views/settings/lookups/JobTrackerLookups.vue')
  },
  {
    path: '/settings/lookups/contract',
    name: 'Contracts Lookups',
    meta: { 
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "ContractLookups" */ '../views/settings/lookups/ContractLookups.vue')
  },
  {
    path: '/settings/lookups/conversion',
    name: 'Conversions Lookups',
    meta: { 
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "ConversionLookups" */ '../views/settings/lookups/ConversionLookups.vue')
  },
  {
    path: '/settings/lookups/financials',
    name: 'Financials Lookups',
    meta: { 
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "FinancialsLookups" */ '../views/settings/lookups/FinancialsLookups.vue')
  },
  {
    path: '/settings/lookups/serviceschedule',
    name: 'Service Schedule Lookups',
    meta: { 
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "ServiceScheduleLookups" */ '../views/settings/lookups/ServiceScheduleLookups.vue')
  },
  {
    path: '/settings/lookups/locations',
    name: 'Locations Lookups',
    meta: { 
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "LocationLookups" */ '../views/settings/lookups/LocationLookups.vue')
  },
  {
    path: '/settings/users',
    name: 'Users',
    meta: { 
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "users" */ '../views/settings/Users.vue')
  },
  {
    path: '/settings/roles',
    name: 'Roles',
    meta: { 
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "users" */ '../views/settings/Roles.vue')
  },
  {
    path :'*',
    name: '404',
    component:() => import(/* webpackChunkName: "404" */ '../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  //redirect to dashboard if already loggedin user tries to login
  if(to.name === 'Login' && store.getters[`${STORE.AUTH}/authorized`]) {
    store.commit(`${STORE.APP}/SET_TITLE`, "Dashboard");
    next({name: 'Dashboard'});
    return;
  }
  //for authorized routes
  if(to.meta?.requiresAuth) {
    if(store.getters[`${STORE.AUTH}/authorized`]) {
      store.commit(`${STORE.APP}/SET_TITLE`, to.name || "CERTUS");
      next();
    } else {

      store.commit(`${STORE.APP}/SHOW_NOTIFICATION`, { message: AppConstants.PROMPT_LOGIN , type: SNACKBAR.WARNING });
      next({name: 'Login'});
    }
  } else {
    store.commit(`${STORE.APP}/SET_TITLE`, to.name || "CERTUS");
    next();
  }
});

export default router
