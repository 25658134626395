import { STORE } from '../../../enums/store';
import LocationLookupService from '../../../services/lookups/locationLookupService';
export default {
    LOAD_LOCATION_LOOKUPS({ dispatch }, utils) {
        dispatch(`${STORE.LOCATION_LOOKUP}/LOAD_LOCATIONS`, utils, { root: true });      
    },
    LOAD_LOCATIONS({ commit }, utils) {
        const locationLookupService = new LocationLookupService(utils);
        locationLookupService.getLocations().then(resp => {
            commit(`${STORE.LOCATION_LOOKUP}/SET_LOCATIONS`, resp, { root: true });
        }); 
    }
}