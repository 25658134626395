import { STORE } from '../../../enums/store';
import ContractLookupService from '../../../services/lookups/contractLookupService';
export default {
    LOAD_CONTRACT_LOOKUPS({ dispatch }, utils) {
        dispatch(`${STORE.CONTRACT_LOOKUP}/LOAD_TYPE_OF_CONTRACTS`, utils, { root: true });      
    },
    LOAD_TYPE_OF_CONTRACTS({ commit }, utils) {
        const contractLookupService = new ContractLookupService(utils);
        contractLookupService.getTypeOfContracts().then(resp => {
            commit(`${STORE.CONTRACT_LOOKUP}/SET_TYPE_OF_CONTRACTS`, resp, { root: true });
        }); 
    }
}