import Vue from 'vue'
import App from './App.vue'
//import './registerServiceWorker'   // for PWA
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueApexCharts from 'vue-apexcharts'
import BaseButton from './components/BaseButton.vue'
import BaseInput from './components/BaseInput.vue'
import BaseSelect from './components/BaseSelect.vue'
import BaseSelectWithOptions from './components/BaseSelectWithOptions.vue'
import BaseDialog from './components/BaseDialog.vue'
import BaseProgressBar from './components/BaseProgressBar.vue'
import BaseFieldSet from './components/BaseFieldSet.vue'
import BaseDatePicker from './components/BaseDatePicker.vue'
import BaseCheckBox from './components/BaseCheckBox.vue'
import BaseConfirmPopup from './components/BaseConfirmPopup.vue'
import BaseTooltip from './components/BaseTooltip.vue'
Vue.use(VueApexCharts)

//global component registration
Vue.component('apexchart', VueApexCharts);
Vue.component('BaseButton', BaseButton);
Vue.component('BaseInput', BaseInput);
Vue.component('BaseSelect', BaseSelect);
Vue.component('BaseSelectWithOptions', BaseSelectWithOptions);
Vue.component('BaseDialog', BaseDialog);
Vue.component('BaseProgressBar', BaseProgressBar);
Vue.component('BaseFieldSet', BaseFieldSet);
Vue.component('BaseDatePicker', BaseDatePicker);
Vue.component('BaseCheckBox', BaseCheckBox);
Vue.component('BaseConfirmPopup', BaseConfirmPopup);
Vue.component('BaseTooltip', BaseTooltip);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
