<template>
  <div>
    <label class="label" :class="{'required': required, 'nowrap': inlineLabel}">{{label}}</label>
    <v-text-field
        outlined
        dense
        :rules="rules"
        :value="value"
        @input="valueChanged"
        @keyup.enter="$emit('enter')"
        :disabled="disabled"
        :readonly="readonly"
        :type="type"
        :hint="hint"
        :prefix="prefix"
        :prepend-inner-icon="prepend_icon"
        :append-icon="append_icon"
        @blur="$emit('blur')"
        @click:append="$emit('eventAppendIconClicked')"
    ></v-text-field>
  </div>
</template>

<script>
export default {
    props: {
        value: {
            default: "",
        },
        label: {
            required: true
        },
        required: {
            type: Boolean,
            default: false
        },
        rules: {
            
        },
        type: {
            default: "text"
        },
        hint: {
            
        },
        prepend_icon: {
            default: ""
        },
        prefix: {
            default: ""
        },
        append_icon: {
            default: ""
        },
        uppercase: {
            type: Boolean,
            default: false
        },
        inlineLabel: {
            type: Boolean,
            default: false
        },
        nospace: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    methods : {
        valueChanged(val) {
            //to prevent error in null value
            let value = val;
            if(this.nospace) {
                value = val?.trim().split(' ').join('') || "";
            }
            if(this.uppercase) {
                value && this.$emit("input", this.uppercase ? value.toUpperCase() : value);
            } else {
                this.$emit("input", value);
            }
        }
    }
}
</script>