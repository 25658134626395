import Vue from 'vue';
export default {
    SET_IS_NEW_JOB(state, payload) {
        state.is_new_job = payload;
    },
    SET_SELECTED_VEHICLE(state, payload) {
        state.selected_vehicle = payload;
    },
    SET_SELECTED_JOB_ID(state, payload) {
        state.selected_job_id = payload;
    },
    SET_SELECTED_JOB_TRACKERS_ID(state, payload) {
        state.selected_job_trackers_id = payload;
    },
    SET_COMPLETED_JOBS(state, payload) {
        state.completed_jobs = payload;
    },
    SET_COMMENTS(state, payload) {
        state.comments = payload;
    },
    SET_IS_INCIDENT(state, payload) {
        state.is_incident = payload;
    },
    SET_READONLY(state, payload) {
        state.readonly = payload;
    },
    SET_JOB_STATUSES(state, payload) {
        state.job_statuses = payload;
    },
    SET_SELECTED_JOB_ITEMS(state, payload) {
        state.selected_job_items = payload;
    },
    SET_JOB_STATUS(state, payload) {
        Vue.set(state.selected_job, 'status', Number.parseInt(payload));
    },
    SET_JOB_VOR(state, payload) {
        Vue.set(state.selected_job, 'vor', payload)
    },
    SET_JOB_PO_NO(state, payload) {
        Vue.set(state.selected_job, 'po_no', payload)
    },
    SET_WORK_PROGRESS_STATUS(state, payload) {
        Vue.set(state.selected_job, 'work_progress_status', payload)
    },
    SET_JOB_MILEAGE(state, payload) {
        Vue.set(state.selected_job, 'mileage', payload)
    },
    SET_LAST_RECORDED_MILEAGE(state, payload) {
        Vue.set(state.selected_job, 'last_recorded_miles', payload)
    },
    SET_JOB_NEXT_DUE_DATE(state, payload) {
        Vue.set(state.selected_job, 'next_due_date', payload)
    },
    SET_JOB_DATE_RAISED(state, payload) {
        Vue.set(state.selected_job, 'date_raised', payload)
    },
    SET_JOB_DATE_COMPLETED(state, payload) {
        Vue.set(state.selected_job, 'date_completed', payload)
    },
    SET_JOB_SUPPLIER_ID(state, payload) {
        Vue.set(state.selected_job, 'supplier_id', payload)
    },
    SET_JOB_FILES(state, payload) {
        Vue.set(state.selected_job, 'files', payload)
    },
    SET_JOB_DOCUMENTS_PATH(state, payload) {
        Vue.set(state.selected_job, 'documents_path', payload)
    },
    SET_JOB_ORIGINAL_DOCUMENTS_PATH(state, payload) {
        Vue.set(state.selected_job, 'original_documents_path', payload)
    },
    SET_JOB_DOCUMENT_ID(state, payload) {
        Vue.set(state.selected_job, 'document_id', payload)
    },
}